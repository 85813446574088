import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (localStorage.getItem('access_token')) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (localStorage.getItem('access_token')) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/reset-password/:code',
        name: 'reset-password',
        component: () => import('../views/pages/account/reset-password'),
        meta: {
            // beforeResolve(routeTo, routeFrom, next) {
            //     // If the user is already logged in
            //     if (store.getters['auth/loggedIn']) {
            //         // Redirect to the home page instead
            //         next({ name: 'home' })
            //     } else {
            //         // Continue to the login page
            //         next()
            //     }
            // },
        },
        props: true
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/fx-hedge/index')
    },
   
    {
        path: '/knowledgehub',
        name: 'knowledgehub',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/knowledgehub')
    },
    {
        path: '/knowledgehub/:id',
        name: 'knowledgehub-article',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/knowledgehub-article'),
        props: true
    },
    {
        path: '/fx-hedge/:target/:desc/:id',
        name: 'fx-hedge-details',
        meta: {
           // role:['fx_hedge'],
            authRequired: true,
        },
        component: () => import('../views/pages/fx-hedge/details'),
        props: true
    },
    {
        path: '/users',
        name: 'users',
        meta: {
            role:['administrator'],
            authRequired: true,
        },
        component: () => import('../views/pages/admin/users')
    }
]
